import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Typography from 'components/atoms/Typography';

import {
  StyledImage,
  StyledSpecialistBanner,
  StyledTag,
  StyledTagsWrapper,
} from './SpecialistBanner.styles';

import { SpecialistBannerTypes } from './models.d';

const SpecialistBanner = ({ image, heading, description, tags }: SpecialistBannerTypes) => (
  <StyledSpecialistBanner data-testid="specialist-banner">
    <StyledImage image={image.image} alt={image.alt} objectFit="contain" />
    <Typography
      asEl="h2"
      fontWeight={500}
      size={{ base: 24, lg: 36, xl: 48 }}
      letterSpacing="-0.02em"
      lineHeight={1.2}
      padding={{ bottom: 16, top: { base: 0, lg: 40, xl: 68 } }}
      color="gray-800"
    >
      {heading}
    </Typography>
    <Typography
      color="gray-700"
      size={{ base: 18, lg: 24, xl: 30 }}
      lineHeight={1.33}
      fontWeight={300}
      padding={{ bottom: { base: 16, lg: 40 } }}
    >
      {renderRichText(description)}
    </Typography>
    <StyledTagsWrapper data-testid="tags-wrapper">
      {tags?.map((tag) => (
        <StyledTag
          key={tag}
          color="gray-900"
          padding={{
            bottom: { base: 8, lg: 12, xl: 16 },
            top: { base: 8, lg: 12, xl: 16 },
            left: { base: 16, lg: 24, xl: 32 },
            right: { base: 16, lg: 24, xl: 32 },
          }}
          size={{ base: 16, md: 18, lg: 20, xl: 24 }}
          lineHeight={1.33}
          fontWeight={500}
        >
          {tag}
        </StyledTag>
      ))}
    </StyledTagsWrapper>
  </StyledSpecialistBanner>
);

export default SpecialistBanner;
